import React, { Suspense, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import logo from './logo.svg';
import './App.css';
import AppBar from './components/AppBar/AppBar';
import { Box, Button, ButtonGroup, Card, CardMedia, CircularProgress, Container, createStyles, Dialog, Divider, Grid, IconButton, makeStyles, Modal, Paper, TextField, Theme, ThemeProvider, Typography } from '@material-ui/core';
import { ItemCard } from './components/MenuItemCard/ItemCard';
import CloseIcon from '@material-ui/icons/Close';
import classes from '*.module.sass';
import SimpleModal from './components/OrderModal/OrderModal';
import ResponsiveDialog from './components/OrderModal/OrderModal';
import Home from './pages/home/Home';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { createTheme } from '@material-ui/core/styles';
import createHistory from 'history/createBrowserHistory'

import {
  // BrowserRouter as Router,
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MenuPage from './pages/home/Menu';
import { useEffect } from 'react';

ReactGA.initialize('UA-202733761-1');
ReactPixel.init('271238411368981');

const font = "'Karla', sans-serif";

export const carouselDishes = [
  // {
  //   id: 'lomo-saltado-w-veggies',
  //   name: 'Lomo Saltado w/ Sautee Veggies Side',
  //   image: 'lomo-saltado-w-veggies-round',
  //   sticker: true
  // },
  {
    id: 'lomo-saltado-w-rice',
    name: 'Lomo Saltado Tenderloin',
    image: 'lomo-saltado-w-rice-round',
    sticker: true
  },
  {
    id: 'chinese-peruvian-sweet-chili-chicken',
    name: 'Chinese Peruvian Sweet Chilli Chicken',
    image: 'chinese-peruvian-sweet-chilli-chicken-round',
    sticker: true
  },
  {
    id: 'crusted-chicken',
    name: 'Crusted Chicken Wok',
    image: 'crusted-chicken-wok-round'
  },
  {
    id: 'veggies-wok',
    name: 'Veggie Wok',
    image: 'veggie-wok-round'
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#e3212f',
      light: '#FFD2D2'
    },
    // secondary: {
    //   main: '#000'
    // },
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontFamily: font,
    h3: {
      fontWeight: 800
    }
  },
  // typography: {
  //   fontFamily: font,
  //   h5: {
  //     fontWeight: 'bold',
  //   },
  //   subtitle1: {
  //     lineHeight: 'normal',
  //   },
  //   subtitle2: {
  //     lineHeight: 'normal',
  //   },
  // },
  // props: {
  //   MuiButton: {
  //     disableElevation: true,
  //   },
  // },
  overrides: {
    MuiButton: {
      root: {
        // fontSize: '5rem',
        borderRadius: '30px',
        padding: '10px 20px',
        textTransform: 'inherit',
        display: 'inline-block',
        position: 'relative',
        // padding: 10px 20px,
        fontSize: '18px',
        fontWeight: 800,
        outline: 0,
        // cursor: 'pointer',
        // fontFamily: 'Karla', sans-serif,
        lineHeight: 'normal',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'background-color .2s,border-color .2s,color .2s',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#cc1925',
          boxShadow: 'none'
        }
      },
      contained: {
        fontWeight: 800,
        border: '1px solid #e3212f',
        // background-color: #e3212f'
        color: '#fff',
        '&:hover': {
          border: '1px solid #cc1925',
          backgroundColor: '#cc1925',
          color: '#fff'
        }
      }
    },
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       borderRadius: '30px'
  //     },
  //     // contained: {
  //     //   fontSize: '16px',
  //     //   fontWeight: 'bold',
  //     //   lineHeight: 'normal',
  //     //   padding: '10px 20px',
  //     // },
  //     // outlined: {
  //     //   fontSize: '16px',
  //     //   fontWeight: 'bold',
  //     //   lineHeight: 'normal',
  //     //   padding: '9px 20px',
  //     // },
  //     // outlinedPrimary: {
  //     //   border: '1px solid #ff0000',
  //     // },
  //     // containedSizeSmall: {
  //     //   fontSize: '10px',
  //     //   padding: '12px 20px',
  //     // },
  //     // containedSizeLarge: {
  //     //   fontSize: '16px',
  //     //   padding: '12px 30px',
  //     //   width: '100%',
  //     //   '@media (min-width: 750px)': {
  //     //     padding: '15px 30px',
  //     //     width: '318px',
  //     //     '&.btn-w235': {
  //     //       width: '235px',
  //     //     },
  //     //   },
  //     // },
  //     // outlinedSizeSmall: {
  //     //   fontSize: '10px',
  //     //   padding: '12px 20px',
  //     //   '&:hover': {
  //     //     backgroundColor: 'rgb(178, 0, 0)',
  //     //     color: '#fff',
  //     //   },
  //     // },
  //     // outlinedSizeLarge: {
  //     //   fontSize: '16px',
  //     //   padding: '10px 30px',
  //     //   '&:hover': {
  //     //     backgroundColor: '#ff0000',
  //     //     color: '#fff',
  //     //   },
  //     //   '@media (min-width: 750px)': {
  //     //     padding: '14px 30px',
  //     //     width: '235px',
  //     //   },
  //     },
  //   // },
  //   // MuiButtonGroup: {
  //   //   grouped: {
  //   //     minWidth: '128px'
  //   //   },
  //   //   groupedOutlinedPrimary: {
  //   //     '&:hover': {
  //   //       borderColor: 'rgb(178, 0, 0)',
  //   //     },
  //   //   },
  //   // },
  //   // MuiDivider: {
  //   //   root: {
  //   //     backgroundColor: '#c2c2c2',
  //   //   },
  //   // },
  //   // MuiIconButton: {
  //   //   root: {
  //   //     color: '#000',
  //   //   },
  //   // },
  //   // MuiFormHelperText: {
  //   //   root: {
  //   //     marginTop: 0,
  //   //   },
  //   //   contained: {
  //   //     marginLeft: 'auto',
  //   //     marginRight: 0,
  //   //   },
  //   // },
  //   // MuiTypography: {
  //   //   body1: {
  //   //     fontSize: '16px',
  //   //     lineHeight: 1.63,
  //   //     marginBottom: '12px',
  //   //   }
  //   // },
  //   // MuiInputBase: {
  //   //   root: {
  //   //     marginBottom: '8px',
  //   //   },
  //   // },
  // },
});


function rand() {
  return Math.round(Math.random() * 20) - 10;
}


// export default App;

const App : React.FC = () => {
  const history = createHistory()

  // const userState = useSelector((state: rootStore) => state.user);
  // const dispatch = useDispatch();
  // const [init, setInit] = useState(false);
  // useEffect(() => {
  //   if (userState && userState.isAuthUser) {
  //     dispatch(getUser(userState.id));
  //     dispatch(getUserConvocatorias(userState.id));
  //   }

  // }, []);
  history.listen((location) => {
    ReactPixel.pageView();
    ReactGA.pageview(location.pathname + window.location.search);
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div style={{'marginTop': '35vh', 'textAlign': 'center'}}><CircularProgress color="primary" /></div>}>
        <Router history={history}>
          <div className="App">
            <Switch>
              <Route exact path="/">
               <Home />
              </Route>
              <Route exact path="/menu">
                <MenuPage />
              </Route>
            </Switch>
          </div>
        </Router>
      </Suspense>
    </ThemeProvider>
  )
};

export default App;
