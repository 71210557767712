import React, { useState, useEffect } from 'react';
// import menu
import Carousel, { slidesToShowPlugin, slidesToScrollPlugin, Dots } from '@brainhubeu/react-carousel';

import '@brainhubeu/react-carousel/lib/style.css';
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { menuDict } from '../../pages/home/Menu';
import ResponsiveDialog from '../OrderModal/OrderModal';
import { carouselDishes } from '../../App';

// const menuD = carouselDishes;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      display: '-webkit-box; display: flex',
      WebkitBoxOrient: 'vertical',
      flexDirection: 'column',
      minHeight: '316px',
      WebkitBoxAlign: 'center',
      alignItems: 'center',
      // width: '240px',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      },
      paddingTop: '30px',
      position: 'relative'
    },
    dotsGroup: {
      paddingTop: '24px'
    },
    menuImage: {
      display: '-webkit-box; display: flex',
      '& img': {
        objectFit: 'cover',
        maxHeight:'520px',
        maxWidth: '520px',
        [theme.breakpoints.down("sm")]: {
          width: '100%',
          height: '100%'
        }
      }
    },
    menuName: {
      color: '#000',
      fontSize: '18px',
      textAlign: 'center',    
      marginBottom: theme.spacing(4),
      lineHeight: 'normal',
      marginTop: theme.spacing(4)
    },
    menuSticker: {
      position: 'absolute',
      // right: '-30px',
      right: 0,
      top: '-5px',
      // width: '90px',
      // height: '90px',
      width: '120px',
      height: '120px',
      zIndex: 1,
      [theme.breakpoints.up("sm")]: {
        right: '50%',
        transform: 'translate(50%,0)'
      }
    },
  }),
);

// const menuD = menuDict;


interface CarouselPropsI {
  // dishID: string;
  setDishID: any;
}

const CarouseC: React.FC<CarouselPropsI> = ({...props}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<any>({});
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOpen = (p: any) => {
    // setOpen(true);
    // setProduct(menuD['main'].find((m) => m.id === p));
  };
  const handleClose = () => {
    setOpen(false);
  }
  const [images, setImages] = useState<any>([]);
  const [modalState, setModalState] = useState<number>(0);
  const [defaultNumber, setDefaultNumber] = useState(3);
  useEffect(() => {
    setImages(carouselDishes);
  }, [])

  const handleChange = (value: any) => {
    console.log({value});
    if (value < 0) { 
      setModalState(0);
      props.setDishID(0);
    }
    else if (value >= images.length) {
      setModalState(images.length - 1);
      props.setDishID(images.length - 1);
    }
    else {
      setModalState(value);
      props.setDishID(value);
    }
  }

  return (
    <React.Fragment>
    {/* <ResponsiveDialog open={open} onClose={handleClose} product={product}/> */}
    <Carousel
      value={modalState}
      onChange={handleChange}
      plugins={[
        'fastSwipe',
        {
          resolve: slidesToShowPlugin,
          options: {
          // numberOfSlides: fullScreen && 1 || 3 // change to responsive
            numberOfSlides: 1
          }
        },
        {
          resolve: slidesToScrollPlugin,
          options: {
            numberOfSlides: 1
            //  numberOfSlides: fullScreen && 1 || 3
          }
        },
      ]}
    >
      {
        images.map((dish: any, id: number) => {
          return (
            <div className={classes.menu} key={id + dish.name} onClick={() => handleOpen(dish.id)} >
              <picture className={classes.menuImage}>
                <source media="(max-width: 749px)" srcSet={`/images/menu/${dish.image}.webp`} />
                <source media="(min-width: 750px)" srcSet={`/images/menu/${dish.image}@2x.webp`} />
                <img src={`/images/menu/${dish.image}.webp`} alt={dish.name} />
              </picture>
              {/* <picture className={classes.menuImage}>
                <source media="(max-width: 749px)" srcSet={`/images/menu/${dish.image}-round-hidef.png`} />
                <source media="(min-width: 750px)" srcSet={`/images/menu/${dish.image}-round-hidef.png`}/>
              <source media="(min-width: 1600px)" srcSet={`/images/menu/${dish.image}-round-hidef.png`}/>
                <img src={`/images/menu/${dish.image}-round-hidef.png`} alt={dish.name} width="670" height="670"/>
              </picture> */}
              {/* <h4 className={classes.menuName}>{dish.name}</h4> */}
              { dish.sticker &&
                <img src="/images/sticker@2x.webp" className={classes.menuSticker} height={'120'} width={'120'}/>
              }
            </div>
          )
        })
      }
    </Carousel>
    <Dots value={modalState} onChange={handleChange} number={images.length} className={classes.dotsGroup} />
    </React.Fragment>
  );
}




export default CarouseC;