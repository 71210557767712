import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },
    title: {
      fontSize: theme.spacing(4),
      fontWeight: 800,
      lineHeight: '37px'
    },
    newsletter: {
      margin: '0 auto 42px',
      maxWidth: '377px',
    }
  }),
);

const AlertModal = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    let modal_status = localStorage.getItem('modal_status');
    if(modal_status === null){
      setOpen(true);
      localStorage.setItem('modal_status', '1');
    }
  },[])
  if(!open) return null;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        disableBackdropClick
        scroll={'body'}
      >
        <DialogTitle disableTypography id="alert-dialog-title">
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="text-center">
          <img src="/images/icons/peruvian-wok.svg" alt=""/>
          <h2 className={`${classes.title} pk-mt-20`}>Exclusive deals are here!</h2>
          <div className={classes.newsletter}>
            <SubscriptionForm modal={true} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AlertModal