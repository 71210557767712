import React, { useState } from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        maxWidth: theme.spacing(40),
        width: '100%',
      },
      marginTop: theme.spacing(4)
    },
    form: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '278px',
        width: '100%',
      },
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '302px'
    },
    paragraph: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '21px',
      marginTop: '17px'
    },
    highlighted: {
      color: theme.palette.primary.main,
      fontWeight: 800
    },
    submitButton: {
      maxWidth: '278px',
      width: '100%'
    }
  }),
);

interface SubscriptionFormPropsI {
  modal: boolean
}

const SubscriptionForm: React.FC<SubscriptionFormPropsI> = ({
  modal = false
}) => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: ''
  });

  const handleSubmit = async () => {
    const data = {
      fname: formData.fullName.split(' ').slice(0, -1).join(' '),
      lname: formData.fullName.split(' ').slice(-1).join(' '),
      email: formData.email,
      brand: 'peruvianwok',
      query: ''
      // query: window.location.search || ''
    }
    const q_param = window.location.search
    if (q_param) {
      console.log('si tienenene', q_param)
      data['query'] = q_param;
    }
    // const token = 'EAAAEJ3l80oRjtviVc88hF3e3p_XqtOHmMz-3Z2n3Qrlh9VWEp4GQyvQnAFJFFWr';

    try {
      const resp = await axios.post('/subscribe-newsletter', data);
      console.log({resp});
      const user_id = resp.data.squareID;
      // append user to localstorage
      const dataSave = {
        user_id: resp.data.squareID
        // user_email: resp.data.customer.email
      };
  
    localStorage.setItem('peruvianwok_userid', dataSave.user_id);
    //   // append to group
      setIsSubmit(true);
    } catch (e) {
      console.log({e});
    //   // return res.status(e.status || 500).end(e.message);
    }
    // try {
    //   const resp = await axios.post('p, data);
    //   const user_id = resp.data.customer.id;
    //   // append user to localstorage
    //   const dataSave = {
    //     user_id: resp.data.customer.id
    //     // user_email: resp.data.customer.email
    //   };
  
    //   localStorage.setItem('peruvianwok_userid',dataSave.user_id);
    //   // append to group
    //   setIsSubmit(true);
    // } catch (e) {
    //   console.log({e});
    // }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

    checkIputValues();
  };

  const checkIputValues = () => {
    if (formData.email && formData.fullName) {
      return false;
    }
    return true;
  }


  return (
    <div>
      { isSubmit && 
        <React.Fragment>
          { modal && 
            <div>
              <p>Thank you for your interest 🇵🇪🦙 <br/><br/>You will shortly receive a mail with more information about Peruvian Wok</p>
            </div>
            ||
            <div style={{ height: '320px'}}>
              <p>Thank you for your interest 🇵🇪🦙 You will shortly receive a mail with more information about Peruvian Wok</p>
            </div>
          }
        </React.Fragment>
      }
      { !isSubmit &&
        <React.Fragment>
          <p className={(modal ? classes.paragraph : '')}>
            Serving the South Bay. <br/>Get a <span className={classes.highlighted}>$45 coupon for your next order</span>.
          </p>
          <form className={(modal ? classes.form : classes.root)} noValidate autoComplete="off">
            <div>
              <TextField
                label="Full name"
                variant="outlined"
                id="full_name"
                name="fullName"
                size="small"
                value={formData.fullName}
                onChange={handleChange}
              >
              </TextField>
              <TextField
                label="E-mail"
                variant="outlined"
                name="email"
                id="email"
                size="small"
                onChange={handleChange}
                value={formData.email}
              >
              </TextField>
            </div>
            { modal &&
              <React.Fragment>
                <div className="pk-hint is-small">*By joining you accept our <a href="https://www.perukitchens.com/privacy_policy" target={"_blank"} style={{"color": 'blue'}}>Privacy Policy</a> and grant full permission to be sent offers and updates</div>
                <div className="pk-pt-30">
                  <button className={`pk-btn pk-btn-cta is-xsmall pk-btn-rounded ${classes.submitButton}`} type="button" onClick={handleSubmit} >
                    <span className="pk-btn-text">
                      <span className="pk-btn-copy">Join now</span>
                    </span>
                  </button>
                </div>
              </React.Fragment>
              ||
              <React.Fragment>
                <div className="pk-hint">*By clicking Submit you accept our <b>Privacy Policy</b> and grant full permission to be sent offers and updates</div>
                <div className="pk-pt-30">
                  <button className="pk-btn pk-btn-cta is-xsmall pk-btn-rounded" type="button" onClick={handleSubmit} >
                    <span className="pk-btn-text">
                      <span className="pk-btn-copy">Submit</span>
                    </span>
                  </button>
                </div>
              </React.Fragment>
            }
          </form>
        </React.Fragment>
      }
    </div>
  )
}

export default SubscriptionForm