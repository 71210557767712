import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    menuBtn: {
      borderBottom: '2px solid red',
      color: 'black'
    }
  }),
);

interface AppBarPropsI {
  page: string,
  handleOpen: any
}

const NavBar: React.FC<AppBarPropsI> = ({
  page = 'lp',
  ...props
}) => {
  const classes = useStyles();
  const handleClick = () => {
    console.log('clicking buitton');
    console.log({props});
    props.handleOpen();
  }

  const [fakeState, setFakeState] = useState<any>();

  function loadFromCookie() {
    try {
      // const serialisedState = localStorage.getItem(`peruvianwok_persistantState`);
      // if (serialisedState === null) return undefined;
      // return JSON.parse(serialisedState);
      let value = Number(Cookies.get('peruvianwok_cartproducts'));
      // if ()
      if (value && value > 0) {
        return value;
      } else {
        return 0
      }
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }
  

  useEffect(() => {
    setFakeState(loadFromCookie());
  }, []);
  return (
    <nav className="navbar navbar-desktop">
      <div className="navbar-content pk-content">
        <div className="navbar-home-link">
          <Link to="/">
            { page == 'lp' &&
              <picture>
                <source media="(max-width: 500px)" srcSet="/images/peruvian-wok-logo-white.png" />
                <source media="(max-width: 799px)" srcSet="/images/peruvian-wok-logo-white@2x.png"/>
                <source media="(min-width: 800px)" srcSet="/images/peruvian-wok-logo-white@3x.png"/>
                <img src="/images/peruvian-wok-logo-white.png" alt=""/>
              </picture>
              || 
              <picture>
                <source media="(max-width: 500px)" srcSet="/images/perucho-wok-logo.png" />
                <source media="(max-width: 799px)" srcSet="/images/perucho-wok-logo@2x.png"/>
                <source media="(min-width: 800px)" srcSet="/images/perucho-wok-logo@3x.png"/>
                <img src="/images/perucho-wok-logo.png" alt=""/>
              </picture>
            }
          </Link>
        </div>

        <ul className="list-horizontal navbar-login-options">
          <li className={ page == 'menu' && `${classes.menuBtn} list-option` || 'list-option'}>
            <Link to="/menu" className="">
              Menu
            </Link>
          </li>
          <li>
            <a onClick={props.handleOpen} className={(page == 'lp' ? 'pk-btn-ghost-white' : 'pk-btn-cta') + " pk-btn is-small pk-btn-rounded"}>Order now</a>
          </li>
          { fakeState && fakeState > 0 && 
          <li>
            <IconButton onClick={() => window.open('https://order.perukitchens.com/peruvianwok', '_blank')}>
              {page !== 'lp' && <><ShoppingCartIcon color={'primary'} /> <Typography component="div" variant="caption">({fakeState})</Typography> </>}
              {page == 'lp' && <> <ShoppingCartIcon style={{ color: 'white' }} /> <Typography style={{ color: 'white' }} component="div" variant="caption">({fakeState})</Typography> </>}
            </IconButton>
          </li> || ''
          }
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;