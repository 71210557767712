import React, { useEffect, useState } from 'react';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';

interface DealsSectionPropsI {
  page: string
}

const DealsSection: React.FC<DealsSectionPropsI> = ({
  page = 'lp'
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!!localStorage.getItem('peruvianwok_userid')) {
      setShow(false);
    }
  }, []);
  
  
  return (
    <React.Fragment>
      { show &&
      <div className="pk-mt-80 pk-pane-section pk-pane-spread pk-pane-odd">
        <div className="pk-pane-img-section" style={{ maxWidth: '578px' }}>
          <div className="pk-pane-img-wrapper">
            <picture className="pk-pane-img">
              <source media="(max-width: 749px)" srcSet="/images/exclusive-deals.webp"/>
              <source media="(min-width: 750px)" srcSet="/images/exclusive-deals@2x.webp"/>
              <img src="/images/exclusive-deals@2x.webp" alt=""/>
            </picture>
          </div>
        </div>
        <div className="pk-pane-text-section">
          <div className="pk-pane-text pk-pane-text-right">
            <h2 className={ page == 'lp' && 'h2' || 'h1'}>
              Exclusive deals!
            </h2>
            <SubscriptionForm modal={false} />
          </div>
        </div>
      </div>
      || ''}
    </React.Fragment>
  );
}
export default DealsSection;