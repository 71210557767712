import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ResponsiveDialog from '../OrderModal/OrderModal';
import { Fade, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 345,
      maxWidth: '100%',
      height: '100%',
      '& .MuiCardActionArea-root': {
        display: '-webkit-box; display: flex;',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%'
      },
      '& .MuiCardContent-root': {
        width: '100%',
        display: '-webkit-box; display: flex;',
        flexDirection: 'column',
        flex: 1
      },
      backgroundColor: '#f9f9f9',
      border: '1px solid #eaeaea',
      borderRadius: '2px'
    },
    dishContainer: {
      height: '100%',
      padding: theme.spacing(2)
    },
    dishDescription: {
      marginBottom: theme.spacing(2)
    },
    dishCalories: {
      display: 'block',
      lineHeight: 1.5
    },
    dishPrice: {
      fontWeight: 700,
      display: 'block',
      lineHeight: 1.5,
      marginTop: 'auto'
    },
    flexbox: {
      display: '-webkit-box; display: flex;',
      WebkitBoxAlign: 'center',
      alignItems: 'center',
      WebkitBoxPack: 'justify',
      justifyContent: 'space-between',
      marginTop: 'auto',
      '& > span': {
        marginRight: theme.spacing(1)
      }
    },
    promoWrapperIcon: {
      [theme.breakpoints.up("xs")]: {
        left: '190px'
      }, 
      [theme.breakpoints.down("sm")]: {
        left: '70%'
      },
      [theme.breakpoints.up("md")]: {
        left: '300px'
      },
      [theme.breakpoints.up("lg")]: {
        left: '320px'
      }
    }
  }),
);

interface ItemCardI {
  item: any;
  i: number;
}

export const ItemCard: React.FC<ItemCardI> = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [show, setShow] = useState(false);
  const handleCardClick = () => {
    // open product card
  }

  useEffect(() => {
    console.log({ props });
    const timer = setTimeout(() => {
      // console.log('This will run after 1 second!')
      setShow(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const openCard = (item: any) => {
    ReactGA.event({
      category: `Peruvian Wok - MenuItem`,
      action: `${item.name} - ${item.promo && 'PROMO - ' || ' '}clicked`,
      label: `clicked an item card from the menu`
      // value: ``
    });
    // setOpen(true);
    if (item.ecom_uri) {
      window.open(item.ecom_uri, '_blank');
    } else {
      setOpen(true);
    }
  }

  return (
    // <Fade in={show}>

    <div className={classes.dishContainer}>
      <ResponsiveDialog open={open} onClose={() => setOpen(false)} product={props.item} />
      <Card style={{ position: 'relative'}} className={classes.root} onClick={() => openCard(props.item)} elevation={0}>
       {/* { props.item.promo && (<div className={classes.promoWrapperIcon} style={{ position: 'absolute', zIndex: 20, top: '10px'}}><img height={!fullScreen && "100" || "80"} src="/images/icons/BOGO_promo.svg"/></div>) || null} */}
        <CardActionArea>
          <CardMedia
            component="img"
            alt={`Dish of ${props.item.name}`}
            height="220"
            // image={`/images/menu/${props.item.image}.webp`}
            image={props.item.image_url && props.item.image_url + '?width=800' || '/images/menu/thumbnail-menu@2x.webp'}
            title={props.item.name}
          />
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="h2">
              <b>{props.item.name}</b>
            </Typography>
            <Typography variant="body1" component="p" className={classes.dishDescription}>
              {props.item.description}
            </Typography>
            <div className={classes.flexbox}>
              {props.item.price &&
                <span className={classes.dishPrice}>${props.item.price.toFixed(2)}</span>
              }
              <div className={classes.flexbox}>
                {props.item.calories &&
                  <span className={classes.dishCalories}>{props.item.calories} cal</span>
                }
                {props.item.tags &&
                  <div className="pk-tags">
                    {props.item.tags.spicy &&
                      <div className="pk-tag is-standalone">
                        <img className="pk-tag-icon" src="/images/icons/spicy.svg" alt="" />
                      </div>
                    }
                    {props.item.tags.vegan &&
                      <div className="pk-tag is-standalone">
                        <img className="pk-tag-icon" src="/images/icons/vegan.svg" alt="" />
                      </div>
                    }
                    {props.item.tags.gluten_free &&
                      <div className="pk-tag is-standalone">
                        <img className="pk-tag-icon" src="/images/icons/gluten-free.svg" alt="" />
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
    // </Fade>
  );
}
