// @ts-nocheck

import React, { Suspense, useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import logo from './logo.svg';
// import './App.css';
import NavBar from '../../components/AppBar/AppBar';
import { Box, Button, ButtonGroup, Card, CardMedia, CircularProgress, Container, createMuiTheme, createStyles, Dialog, Divider, Fade, Grid, IconButton, makeStyles, Modal, Paper, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { ItemCard } from '../../components/MenuItemCard/ItemCard';
import CloseIcon from '@material-ui/icons/Close';
import classes from '*.module.sass';
import ResponsiveDialog from '../../components/OrderModal/OrderModal';
// import Home from './pages/home/Home';

import {
  BrowserRouter as Router,
  // Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Footer from '../../components/Footer/Footer';
import DealsSection from '../../components/DealsSection/DealsSection';
import axios from 'axios';
// import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    card: {
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      color: 'black',
      backgroundColor: 'white'
    },
    // dish:{
    //   padding: theme.spacing(2)
    // }
    modalPaper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
  }),
);


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const OrderDialog: React.FC = () => {
  const [modalStyle] = useState(getModalStyle);

  const [open, setOpen] = useState(true);
  // const classes = fSstyles();
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <Container component="main" maxWidth="xs" style={modalStyle} >
          {/* <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton> */}
          {/* {props.children} */}
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  Mi casa
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Container >
      </Modal>
    </React.Fragment>
  );
}

interface MenuSectionsTabPropsI {
  // labels: any;
  // activeLabel: string;
}

const MenuSectionTabButton = withStyles((theme: Theme) => ({
  root: {
    // border: '2px solid #ff0000',
    // fontSize: '14px',
    // fontWeight: 'bold',
    // padding: '10px 20px',
    // width: '145px',
    // '&:hover': {
    //     backgroundColor: '#ff0000',
    //     border: '2px solid #ff0000',
    //     color: '#fff',
    // },
    borderRadius: '0',
    height: '42px',
    fontWeight: 'bold',
    '&:hover': {
      zIndex: 10,
      color: '#e3212f',
      backgroundColor: '#fff',
      border: 'unset',
      borderBottom: '2px solid #e3212f'
    }
  },
  // label: {
  //   borderBottom: '2px solid #e3212f'
  // },
  outlined: {
    backgroundColor: '#fff',
    border: 'unset',
    borderBottom: '2px solid #f9f9f9'
  },
  outlinedPrimary: {
    color: '#828282',
    // fontWeight: 'bold'
  },
  containedPrimary: {
    color: '#e3212f',
    backgroundColor: '#fff',
    border: 'unset',
    borderBottom: '2px solid #e3212f'
  },
  contained: {
    color: '#e3212f',
    backgroundColor: '#fff'
  }
}))(Button);

interface MenuSectionsTabI {
  active: string;
  handleChange: any;
}

const MenuSectionsTab: React.FC<MenuSectionsTabI> = ({ ...props }) => {
  // const [active, setActive] = useState('main');
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box mx="auto" mb={4}>
          <ButtonGroup disableElevation color="primary" className="mstb-container">
            <MenuSectionTabButton size="small" variant={props.active == 'family size' && 'contained' || 'outlined'} onClick={() => props.handleChange('family size')}>{'Family Size'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'signature woks' && 'contained' || 'outlined'} onClick={() => props.handleChange('signature woks')}>{'Signature Woks'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'stir fried rices' && 'contained' || 'outlined'} onClick={() => props.handleChange('stir fried rices')}>{'Stir Fried Rices'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'peruvian burritos' && 'contained' || 'outlined'} onClick={() => props.handleChange('peruvian burritos')}>{'Peruvian Burritos'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'sides' && 'contained' || 'outlined'} onClick={() => props.handleChange('sides')}>{'Sides'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'make it a combo' && 'contained' || 'outlined'} onClick={() => props.handleChange('make it a combo')}>{'Make it a Combo'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'extras & sauces' && 'contained' || 'outlined'} onClick={() => props.handleChange('extras & sauces')}>{'Extras & Sauces'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'drinks' && 'contained' || 'outlined'} onClick={() => props.handleChange('drinks')}>{'Drinks'}</MenuSectionTabButton>
            <MenuSectionTabButton size="small" variant={props.active == 'desserts' && 'contained' || 'outlined'} onClick={() => props.handleChange('desserts')}>{'Desserts'}</MenuSectionTabButton>
          </ButtonGroup>
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export const menuDict = {
  "catering": [
    {
      "id": "asian-peruvian-menu",
      "name": "Asian Peruvian Menu",
      "description": "For 6 people ($18.70 ea): 4 classic Asian-peruvian dishes all of them in our extra-large format (50% more than normal sizes). Acevichado fish bowl, Tuna ceviche nikkei, Chinese Stir-fried Quinoa with Chicken (Chaufa), and Lomo Saltado. Also our classic extras including crispy corn (Canchita), Yellow chili sauce, and Spicy Rocoto sauce.",
      "image": "asian-peruvian-menu",
      "sticker": false,
      "price": 112.00
    }
  ],
  "family size": [
    {
      "id":"lomo-saltado-sirloin-pack",
      "name": "Lomo Saltado Sirloin Pack",
      "description": "4 people ( $11.25 each). Two more for + $20.00",
      "image": "lomo-saltado-sirloin-pack",
      "sticker": false,
      "price": 45.00
    },
    {
      "id":"chinese-peruvian-sweet-chilli-pack",
      "name": "Chinese Peruvian Sweet Chili Chicken Pack",
      "description": "4 people ( $11.25 each). Two more for + $20.00",
      "image": "chinese-peruvian-sweet-c-hilli",
      "sticker": false,
      "price": 45.00
    },
    {
      "id":"chicken-saltado-pack",
      "name": "Chicken Saltado Pack",
      "description": "4 people ( $11.25 each). Two more for + $20.00",
      "image": "chicken-saltado",
      "sticker": false,
      "price": 45.00
    },
    {
      "id":"crusted-chicken-wok-pack",
      "name": "Crusted Chicken Wok Pack",
      "description": "4 people ( $11.25 each). Two more for + $20.00",
      "image": "crusted-chicken-wok",
      "sticker": false,
      "price": 45.00
    }
  ],
  "main": [
    {
      "id":"chickensaltado",
      "square_id": "5LCAKRK5TWFAEV5GFY6GPMZH",
      "name": "Chicken Saltado",
      "description": "Chicken Saltado with a Chinese touch. Tomatoes, red onion, Peruvian chili and a side of fries. Accompanied either by quinoa or rice with Peruvian corn.",
      "image": "chicken-saltado",
      "sticker": true,
      "price": 14.99,
      "calories": "656"
    },
    {
      "id":"chpscc-2x1",
      "name": "2 x 1 Peruvian Sweet Chilli Chicken",
      "description": "Our best Peruvian-Chinese fusion dish, now in a special offer to share with your friends!",
      "image": "chpscc-2x1",
      "price": 15.99,
      "tags": {
        "gluten_free": false,
        "spicy": true,
        "vegan": false
      }
    },
    // {
    //   "id":"chpscc",
    //   "square_id": "3BKAUYAESSWYM6J2GI7ZAMCF",
    //   "name": "Chinese Peruvian Sweet Chilli Chicken",
    //   "description": "A mixture of Peruvian and Chinese food. Chicken breast stir-fried with veggies in a sweet chili. Accompanied either by quinoa or rice with Peruvian corn.",
    //   "image": "chinese-peruvian-sweet-c-hilli",
    //   "sticker": true,
    //   "price": 14.99,
    //   "calories": "605",
    //   "tags": {
    //     "gluten_free": false,
    //     "spicy": true,
    //     "vegan": false
    //   }
    // },
    {
      "id":"lomo-saltado-sirloin",
      "name": "Lomo Saltado Sirloin",
      "description": "Stir fry sirloin beef with veggies and Peruvian yellow chili in an Asian-Peruvian sauce. Served with fries and rice with Peruvian corn.",
      "image": "lomo-saltado",
      "price": 17.99
    },
    {
      "id":"lomosaltado",
      "name": "Lomo Saltado Tenderloin",
      "description": "The star of the show. Juicy Tenderloin Beef, veggies, Peruvian chili,  rice with Peruvian corn and a side of fries.",
      "image": "lomo-saltado",
      "square_id": "FILZLMAHSYAJOB6PUI5MDLHB",
      "sticker": true,
      "price": 20.99,
      "calories": "644"
    },
    {
      "id":"tallarin-saltado-criollo",
      "name": "Lomo Saltado Noodles (Tallarin Saltado Criollo)",
      "description": "For pasta lovers. Juicy Tenderloin Beef, pasta, veggies, Peruvian yellow chili, and an Asian-Peruvian sauce.",
      "image": "tallarin-saltado-criollo",
      "price": 18.99
    },
    {
      "id":"crustedchickenwok",
      "square_id": "TS5BWNY2DW6DAYWJWGBF2Z6J",
      "name": "Crusted Chicken Wok",
      "description": "Our delicious and tender quinoa-crusted chicken is accompanied by tomatoes, Peruvian yellow chili, red onion, and our tasty Szechuan sauce. Choose between a side of quinoa or rice with Peruvian corn.",
      "image": "crusted-chicken-wok",
      "sticker": false,
      "price": 14.99,
      "calories": "884",
      "tags": {
        "gluten_free": true,
        "spicy": false,
        "vegan": false
      }
    },
    {
      "id":"veggiewok",
      "square_id": "EGM5ZNRW36ZFRPLA5UPPFGCE",
      "name": "Veggie Wok",
      "description": "An alternative to our classic Lomo Saltado, using fresh mushrooms and sautéed veggies. Accompanied either by quinoa or rice with Peruvian corn.",
      "image": "veggie-wok",
      "sticker": false,
      // "promo": true,
      "price": 13.99,
      "calories": "465",
      "tags": {
        "gluten_free": true,
        "spicy": false,
        "vegan": true
      }
    }
  ],
  "stir fried rices": [
    {
      "id":"veggie-quinoa-chaufa-snack-size",
      "name": "Veggie Fried Rice - Snack Size",
      "description": "Half order of our unique Veggie Fried Rice.",
      "image": "veggie-quinoa-chaufa-snack-size",
      "price": 6.99,
      "tags": {
        "gluten_free": false,
        "spicy": false,
        "vegan": true
      }
    },
    {
      "id":"chicken-quinoa-chaufa-snack-size",
      "name": "Chicken Fried Rice - Snack Size",
      "description": "Half order of our tasty Chicken Fried Rice.",
      "image": "chicken-quinoa-chaufa-snack-size",
      "price": 8.99,
      "tags": {
        "gluten_free": true,
        "spicy": false,
        "vegan": false
      }
    },
    {
      "id":"veggie-quinoa-chaufa",
      "square_id": "XXC5O2NSSSJZVXZN6WWF6WCT",
      "square_test_id": "OAC5QBHV2C2WQHW63GQEL4OQ",
      "name": "Veggie Fried Rice",
      "description": "Peruvian-style stir fried rice with special soy mix (“Chaufa sauce”) and our local touch. Scrambled eggs, sliced red peppers, fresh broccoli flowerets, and tasty asparagus stalks.",
      "image": "veggie-quinoa-chaufa",
      "price": 12.99,
      "calories": "1016",
      "tags": {
        "gluten_free": false,
        "spicy": false,
        "vegan": true
      }
    },
    {
      "id":"chicken-quinoa-chaufa",
      "square_id": "BQ52PG3OCIS2ORUJPORJ2KAL",
      "square_test_id": "3KSOAJB6J7S432MNFD2VKABF",
      "name": "Chicken Fried Rice",
      "description": "Peruvian-style stir fried rice with special soy mix (“Chaufa sauce”) and our local touch. Chicken, scrambled eggs, sliced red peppers, fresh broccoli flowerets, and tasty asparagus stalks.",
      "image": "chicken-quinoa-chaufa",
      "price": 15.99,
      "calories": "1234",
      "tags": {
        "gluten_free": true,
        "spicy": false,
        "vegan": false
      }
    }
  ],
  "stir fried burritos": [
    {
      "id": "lomo-saltado-burrito-2x1",
      "name": "2 x 1 Lomo Saltado Burrito",
      "description": "Two Mexican Burritos with a Peruvian twist. Enjoy our bestseller with this special offer!",
      "image": "lomo-saltado-burrito-2x1",
      "price": 12.99
    },
    // {
    //   "id": "lomo-saltado-burrito",
    //   "name": "Lomo Saltado Burrito",
    //   "description": "A Mexican Burrito with a Peruvian twist. Lomo Saltado with rice, yellow chili, tomato, avocado, Criollita sauce, cilantro and lettuce drizzled with spicy rocoto wrapped in a flour tortilla.",
    //   "image": "lomo-saltado-burrito",
    //   "price": 12.99
    // },
    {
      "id": "lomo-saltado-burrito-wchicha",
      "name": "Lomo Saltado Burrito w/Fries & Chicha Morada",
      "description": "Our Mexican-Peruvian bestseller now has french fries and our unique Chicha Morada (16oz). Perfect for a fast lunch!",
      "image": "lomo-saltado-burrito-wchicha",
      "price": 17.99
    },
    {
      "id": "chicken-saltado-burrito",
      "name": "Chicken Saltado Burrito",
      "description": "A Mexican Burrito with a Peruvian twist. Chicken Saltado with rice, yellow chili, tomato, avocado, Criollita sauce, cilantro and lettuce drizzled with spicy rocoto wrapped in a flour tortilla.",
      "image": "chicken-saltado-burrito",
      "price": 9.99
    }
  ],
  "sides": [
    {
      "id":"french-fries-portion",
      "name": "French Fries Portion",
      "description": "Golden-crisp and crunchy on the outside and a light, fluffy interior. Sided with our top sellers sauces acevichado, yellow chili and spicy rocoto sauces.",
      "image": "french-fries",
      "price": 9.99
    },
    {
      "id":"quinoacrustedchicken",
      "name": "Chicken Tenders with Quinoa Crust",
      "description": "6 golden quinoa-crusted chicken tenders and a sauce of your preference.",
      "image": "quinoa-crusted-chicken",
      "sticker": false,
      "price": 7.00,
      // "calories": "465"
    },
    {
      "id":"rice",
      "name": "Rice",
      "description": "Moist and fluffy, garlic-infused white rice with a hint of vegetable oil and a dash of salt.",
      "image": "sides-rice",
      "sticker": false,
      "price": 2.00,
      // "calories": "465"
    },
    {
      "id":"quinoa",
      "name": "Quinoa",
      "description": "A side of our soft and healthy Andean quinoa, drizzled with a dash of olive oil.",
      "image": "sides-quinoa",
      "price": 2.00
    }
  ],
  "extras and sauces": [
    {
      "id":"yellowchillisauce",
      "name": "Yellow Chili Sauce",
      "description": "A traditional, creamy Peruvian ahi condiment made from classic, spicy, yellow Peruvian chilis.",
      "image": "yellow-chilli-sauce",
      "sticker": false,
      "price": 1.50,
      // "calories": "465"
    },
    {
      "id":"criollita",
      "name": "Criollita Sauce",
      "description": "Flavorful citrus relish made with julienned onion, Peruvian yellow chili, cilantro, and fresh-squeezed lime juice.",
      "image": "criollita-sauce",
      "sticker": false,
      "price": 1.50,
      // "calories": "465"
    },
    {
      "id":"acevichado-sauce",
      // "square_id": "BQ52PG3OCIS2ORUJPORJ2KAL",
      "square_test_id": "5CC7QKLQDFZC4A6JT7UAIQER",
      "name": "Extra Acevichado Sauce",
      "description": "Citrusy, spicy sauce made from a blend of Leche de Tigre with hints of ginger, chili, and coriander.",
      "image": "extra-acevichado-sauce",
      "price": 1.50,
      "calories": ""
    },
    {
      "id":"spicy-ricotto-sauce",
      "name": "Spicy Rocoto Sauce",
      "description": "A very spicy Peruvian sauce made with Peruvian red hot chili pepper and tomatoes.",
      "image": "spicy-ricotto-sauce",
      "sticker": false,
      "price": 1.50
    }
  ],
  "drinks": [
    {
      "id": "chicha-morada",
      "name": "Chicha Morada",
      "description": "Typical sweet Peruvian beverage made of Purple Corn a hint of lime. Served in a 16 oz bottle.",
      "image": "chicha-morada",
      "price": 4.90
    },
    {
      "id": "passion-fruit",
      "name": "Passion Fruit Beverage",
      "description": "Sweet with a hint of acid beverage made of Passion Fruit. Served in a 16 oz bottle.",
      "image": "passion-fruit",
      "price": 4.90
    },
    {
      "id": "inca-kola",
      "name": "Inca Kola",
      "description": "The famous peruvian soda. 12 oz",
      "image": "inca-kola",
      "price": 3.50
    }
  ],
  "desserts": [
    // {
    //   "id": "sixpack-chocoteja",
    //   "name": "Tejas & Chocotejas Mix - Gift Pack (6 units)",
    //   "description": "Fine tejas and chocotejas with a variety of fillings, including dulce de leche, and nuts or an array of fruits, covered in an outer layer of fondant or chocolate. The sweetest pleasure.",
    //   "image": "sixpack-chocoteja",
    //   "price": 21.00,
    //   "calories": ""
    // },
    {
      "id": "lemon-teja",
      "name": "Lemon Teja",
      "description": "Candied Lemon peel, dulce de leche caramel, smooth fondant. Gluten Free.",
      "image": "lemon-teja",
      "price": 3.60,
      "calories": ""
    },
    {
      "id": "orange-chocoteja",
      "name": "Orange Chocoteja",
      "description": "Candied orange peel from Palpa - Perú, dulce de leche caramel, gourmet chocolate. 45% Pure Cacao & Gluten Free.",
      "image": "orange-chocoteja",
      "price": 3.60,
      "calories": ""
    },
    {
      "id": "pecan-chocoteja",
      "name": "Pecan Chocoteja",
      "description": "Roasted pecans from Ica - Peru, dulce de leche caramel, gourmet chocolate. 45% Pure Cacao & Gluten Free.",
      "image": "pecan-chocoteja",
      "price": 3.60,
      "calories": ""
    }
  ]
  // "desserts": [
  //   {
  //     "id":"alfajores",
  //     "square_id": "7HDQRN6DMKS2XGRPG6MPQF63",
  //     "name": "Alfajores",
  //     "description": "Two delicate, melt-in-your-mouth cookies with dollop of creamy dulce de leche caramel in-between.",
  //     "image": "alfajores",
  //     "price": 5.99
  //   },
  //   {
  //     "id":"brownie",
  //     "square_id": "UJQXIA7XIKVTTM3F5H6PXD67",
  //     "name": "Brownie w/ lucuma frosting and chopped pecans",
  //     "description": "A soft, warm, chocolatey, moist bread topped with an tropical-like creamy flavor.",
  //     "image": "brownie",
  //     "price": 5.99
  //   },
  // ]
};


interface activeType {

}

function MenuPage({menu = {}}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState('signature woks');
  const [content, setContent] = useState<any>([]);
  const [menuData, setMenuData] = useState<any>(menuDict);
  // cons
  useEffect(() => {
    async function getMenu() {
      const response = await axios({
        url: 'https://graphql.us.fauna.com/graphql',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${'fnAEdKE-S7AARO6Dkrz8hH3-9Lnpe18sDu1VYH6u'}`
        },
        data: {
          query : `{
            menuByBrand(brands: "peruvian_wok", ecom_visible: "VISIBLE") {
              data {
                id
                name
                description
                category
                price
                ecom_uri
                image_url
              }
            }
          }`
        }
      });
      console.log({response});
      // const res = await response.json();
      const parsedMenu: {[index: any]:any} = {}
      const data = (typeof response.data.data !== 'undefined') ? response.data.data.menuByBrand.data : null;
      data.forEach((item: any) => {
        console.log({item});
        const key = String(item.category && item.category.toLowerCase() || 'family size');
        if (parsedMenu[key]) {
          parsedMenu[key].push(item);
        } else {
          parsedMenu[key] = [item];
        }
        // parsedMenu[key] = item
      });
      console.log({parsedMenu});
      setMenuData(parsedMenu);
      return { menu: parsedMenu };
    };
    getMenu();
  }, []);
  const handleSectionChange = (sect: string) => {
    console.log({ sect });
    setActive(sect);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      {/* navbar */}
      <NavBar page={"menu"} handleOpen={handleOpen} />
      <ResponsiveDialog open={open} onClose={handleClose} />

      <Container component="main" maxWidth="md" style={{ paddingTop: '120px' }}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} className="text-center">
            <Typography variant="h3" style={{ fontWeight: 800, fontSize: '32px' }}>
              Menu
            </Typography>
          </Grid>
          <MenuSectionsTab active={active} handleChange={handleSectionChange} />
          <Grid container>
            {
              menuData && menuData[active] && menuData[active].map((item: any, i: any) => { // @ts-ignore
                return (
                  <React.Fragment key={i + item.name}>
                    <Fade in={true} style={{ transitionDelay: 50 + ((i + 1) * 50) + 'ms' }}>
                      <Grid item xs={12} sm={6}>

                        <ItemCard item={item} i={i} />
                        {/* </Fade> */}
                      </Grid>
                    </Fade>
                  </React.Fragment>
                );
              })
            }
            {/* <Grid item xs={6} sm={4}>
              <ItemCard  />
            </Grid>
            <Grid item xs={6} sm={4}>
              <ItemCard />
            </Grid>
            <Grid item xs={6} sm={4}>
              <ItemCard />
            </Grid>
            <Grid item xs={6} sm={4}>
              <ItemCard />
            </Grid> */}
          </Grid>
          <div className="pk-tags-container pk-mt-60 pk-mb-80">
            <div className="pk-tags">
              <div className="pk-tag">
                <img className="pk-tag-icon" src="/images/icons/spicy.svg" alt="" />
                <span className="pk-tag-name">Spicy</span>
              </div>
              <div className="pk-tag">
                <img className="pk-tag-icon" src="/images/icons/vegan.svg" alt="" />
                <span className="pk-tag-name">Vegan</span>
              </div>
              <div className="pk-tag">
                <img className="pk-tag-icon" src="/images/icons/gluten-free.svg" alt="" />
                <span className="pk-tag-name">Gluten Free</span>
              </div>
            </div>
          </div>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <div className="pk-content pk pk-mb-80">
            <DealsSection page="menu" />
          </div>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default MenuPage;


