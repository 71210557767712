import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { handleDeliveryClick } from '../../pages/home/Home';

const Footer: React.FC = () => {
  return (
    <footer className="pk main-footer-wrapper">
      <div className="main-footer">
        <section className="main-footer-header pk-content-md text-center pk-mb-40 pk-mb-md-50">
          <h2 className="h2 white">Do you have an event? Check out our <a href="https://perukitchens.com/catering" target="_blank" className="address">catering options</a>.</h2>
          <p className="white">Order online or pick up from our restaurants at <span className="address" onClick={() => window.open('https://order.peruvianwok.perukitchens.com/', '_blank')}>949 Ruff Drive San Jose, CA 95110</span>.</p><p className="white">Order our famous Lomo Saltado, Ceviche, Tacos, Burritos, Pokes and more Peruvian Cravings. Serving San Jose, Santa Clara, Sunnyvale, Campbell and Mountain View.</p>
        </section>
        <div className="pk-content">
          <hr className="main-footer-separator" />
        </div>
        <div className="pk-content">
          <section className="main-footer-columns">
            <div className="main-footer-column">
              <a className="main-footer-menu-option" href="mailto:contactus@perukitchens.com">Contact</a>
              <a className="main-footer-menu-option" href="https://www.perukitchens.com/terms_conditions" target={"_blank"}>Terms & Conditions</a>
              <a className="main-footer-menu-option" href="https://www.perukitchens.com/privacy_policy" target={"_blank"}>Privacy policy</a>
            </div>
            <div className="main-footer-column">
              <div className="main-footer-social">
                <a href="https://www.instagram.com/peruvianwok/" target="_blank" className="main-footer-link">
                  <span className="main-footer-social-icon">
                    <img src="/images/icons/instagram-white.svg" alt=""/>
                  </span>
                </a>
                <a href="https://www.tiktok.com/@peruvianwok?" target="_blank" className="main-footer-link">
                  <span className="main-footer-social-icon">
                    <img src="/images/icons/tik-tok-white.svg" alt=""/>
                  </span>
                </a>
                <a href="https://www.facebook.com/Peruvian-Wok-109791377925760/" target="_blank" className="main-footer-link">
                  <span className="main-footer-social-icon">
                    <img src="/images/icons/facebook-white.svg" alt=""/>
                  </span>
                </a>
              </div>
            </div>
          </section>
          <div className="main-footer-copy pk-mt-20">
            <span className="main-footer-copy-text">Peruvian Wok® 2022 · Peru Kitchens</span>
            <span className="main-footer-copy-text">Phone: <a href="tel:408-560-4689" target="_blank">408-560-4689</a></span>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;