import { useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { carouselDishes } from '../../App'
import NavBar from '../../components/AppBar/AppBar'
import CarouseC from '../../components/Carousel/Carousel'
import DealsSection  from '../../components/DealsSection/DealsSection'
import Footer from '../../components/Footer/Footer'
import ResponsiveDialog from '../../components/OrderModal/OrderModal'
// import { DealsSection } from './Menu'
import { menuDict } from '../../pages/home/Menu';
import AlertModal from '../../components/AlertModal/AlertModal'
import ReactGA from 'react-ga';

// const menuD = carouselDishes;
export const handleDeliveryClick = (e: any) => {
  // console.log(e.currentTarget.href);
  e.preventDefault();
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      action: `peruvianwok_${e.currentTarget.id}_clicked`,
      category: 'peruvianwok - delivery app clicked',
      label: `peruvianwok - ${e.currentTarget.id} clicked`,
      // value: e.currentTarget.id
    });
    // gtag.event({
      
    // });
  } else {
    console.log(e.currentTarget.id, 'peruvianwok');
  }
  window.open(e.currentTarget.href, '_blank');
}

export const handlePickUpClick = (e: any) => {
  e.preventDefault();
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      action: `peruvianwok_${e.currentTarget.id}_clicked`,
      category: 'peruvianwok - pickup clicked',
      label: `peruvianwok - ${e.currentTarget.id} clicked`,
      // value: e.currentTarget.id
    });
    // gtag.event({
      
    // });
  } else {
    console.log(e.currentTarget.id, 'peruvianwok');
  }
  window.open('https://order.peruvianwok.perukitchens.com/', '_blank');
}


const Home = () => {
  const [open, setOpen] = useState(false);
  
  const [dishID, setDishID] = useState(0);

  const [product, setProduct] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleClose = () => {
    setOpen(false);
    setProduct({});
  };
  const handleOpen = () => {
    console.log('trying top pen');
    // setP
    setOpen(true);
  };

  const handleOrderNow = () => {
    // setProduct(carouselDishes[dishID]);
    setOpen(true);
  }


  const handleNavOrderNow = (e: any) => {
    e.preventDefault();
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        action: `peruvianwok - nav button - order now clicked`,
        category: 'peruvianwok - CTA',
        label: `peruvianwok - https://order.peruvianwok.perukitchens.com/`,
      });
    } else {
      console.log(e.currentTarget.id, 'peruvianwok');
    }
    window.open('https://order.peruvianwok.perukitchens.com/?utm_source=lp&utm_medium=peruvianwok_ordernow_nav', '_blank');
  }

  // useEffect(() => {
  //   setProduct(menuD['main'][dishID]);
  // }, [dishID]);

  return (
    <React.Fragment>
     <NavBar page="lp" handleOpen={handleNavOrderNow} />
     <ResponsiveDialog open={open} onClose={handleClose} />
     {/* <ResponsiveDialog open={open} onClose={() => setOpen(false)} product={props.item} /> */}
     <AlertModal />

      <header className="pk pk-header-wrapper">
        <div className="pk-header pk-header-solid is-dark pk-header-short-img">
          <div className="pk-content pk-header-content">
            <div className="pk-header-pattern">
              <img src="/images/header-pattern.svg"/>
            </div>
            <div className="pk-header-copy">
              <h1>The best Lomo Saltado you’ll ever eat</h1>
              <p className="">Order from your favorite delivery app and enjoy within minutes!</p>
              <div className="pk-btn-group">
                <a onClick={handleOrderNow} className={'pk-btn pk-btn-white is-small pk-btn-rounded'}>Start order here</a>
              </div>
              <div className="pk-header-logos">
                <span className="white">Also available on</span>
                <div className="pk-logo">
                  <div className="pk-logo-bar">
                    <a onClick={handleDeliveryClick} id="doordash" href="https://www.doordash.com/store/peruvian-wok-san-jose-1742967/" target="_blank" className="pk-logo-link">
                      <img
                        alt=""
                        src="/images/logos/doordash.svg"
                      />
                    </a>
                    <a onClick={handleDeliveryClick} id="postmates" href="https://postmates.com/store/peruvian-wok-949-ruff-drive/oGOL4UByR3yeTbxLo51iXQ" target="_blank" className="pk-logo-link">
                      <img
                        alt=""
                        src="/images/logos/postmates.svg"
                      />
                    </a>
                    <a onClick={handleDeliveryClick} id="grubhub" href="https://www.grubhub.com/restaurant/peruvian-wok-949-ruff-drive-san-jose/2735297" target="_blank" className="pk-logo-link">
                      <img
                        alt=""
                        src="/images/logos/grub-hub.svg"
                      />
                    </a>
                    <a onClick={handleDeliveryClick} id="ubereats" href="https://www.ubereats.com/store/peruvian-wok-949-ruff-drive/oGOL4UByR3yeTbxLo51iXQ" target="_blank" className="pk-logo-link">
                      <img
                        alt=""
                        src="/images/logos/uber-eats.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <picture className="pk-header-img-wrapper">
              <source media="(max-width: 749px)" srcSet="/images/header-image.webp" />
              <source media="(min-width: 750px)" srcSet="/images/header-image@2x.webp"/>
              <img src="/images/header-image.webp" alt="" className="pk-header-img" />
            </picture>
          </div>
        </div>
      </header>
      {/* <section className="pk pk-mt-60 pk-mt-md-80 pk-mb-80">
        <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row', alignContent: 'center', textAlign: 'center' }}>
          <header className="pk-content pk-mb-40 pk-mb-md-50">
            <h2 className="h1">What are you craving today?</h2>
            <h2 className="h2">{menuD['main'][dishID].name}</h2>
            <a onClick={handleOrderNow} className={'pk-btn-cta pk-btn is-small pk-btn-rounded'}>Order now</a>

          </header>
          <div className="pk-feature-container pk-content" style={{flexDirection: 'column'}}>
            <CarouseC setDishID={setDishID} />
          </div>
        </div>
      </section> */}
      <section className="pk">
        <div className="pk-content">
          <div className="pk-mt-80 pk-pane-section pk-pane-spread">
            <div className="pk-pane-img-section carousel-wrapper">
              <CarouseC setDishID={setDishID} />
              { fullScreen &&
                  <div style={{textAlign: 'center'}}> 
                    <p style={{fontWeight: 'normal', paddingTop: '10px'}}>{carouselDishes[dishID].name}</p>
                    <div className="pk-pt-30 pk-pt-md-40">
                      <a onClick={handleOrderNow} className={'pk-btn pk-btn-transparent is-xsmall pk-btn-rounded'}>Order now</a>
                    </div>
                  </div>
                }
            </div>
            <div className="pk-pane-text-section">
              <div className="pk-pane-text pk-pane-text" style={{ textAlign: 'center'}}>
                <h2 className="h2">
                  What are you craving today?
                </h2>
                { !fullScreen &&
                  <React.Fragment > 
                    <p style={{fontWeight: 'normal', paddingTop: '10px'}}>{carouselDishes[dishID].name}</p>
                    <div className="pk-pt-30 pk-pt-md-40">
                      <a onClick={handleOrderNow} className={'pk-btn pk-btn-transparent is-xsmall pk-btn-rounded'}>Order now</a>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="pk-content">
          <div className="pk-mt-80 pk-pane-section pk-pane-spread">
            <div className="pk-pane-img-section" style={{ position: 'relative', maxWidth: '578px' }}>
              <div className="pk-pane-img-wrapper">
                <picture className="pk-pane-img">
                  <source media="(max-width: 749px)" srcSet="/images/catering-image.webp" />
                  <source media="(min-width: 750px)" srcSet="/images/catering-image@2x.webp" />
                  <img src="/images/catering-image.webp" alt="" />
                </picture>
              </div>
            </div>
            <div className="pk-pane-text-section">
              <div className="pk-pane-text pk-pane-text-right">
                <small className="pk-pane-label">IMMEDIATE ORDERS (5-40 people)</small>
                <h2 className="h2">Instant delivery via our flexible catering options</h2>
                <p>Delight your family, friends and co-workers with an easy to order catering menu.</p>
                <div className="pk-btn-group">
                  <a className="pk-btn pk-btn-cta is-xsmall pk-btn-rounded pk-btn-twin" href="https://catering.perukitchens.com/s/splash" target="_blank">
                    <span className="pk-btn-text">
                      <span className="pk-btn-copy">Order now for 5-40 people</span>
                    </span>
                  </a>
                  <a className="pk-btn pk-btn-ghost-cta is-xsmall pk-btn-rounded pk-btn-twin" href="https://www.ezcater.com/catering/pvt/peruvian-wok-3?fcv=1" target="_blank">
                    <span className="pk-btn-text">
                      <span className="pk-btn-copy">Schedule orders for 40+</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pk-content"> 
          <div className="pk-mt-80 pk-pane-section pk-pane-spread pk-pane-odd">
            <div className="pk-pane-img-section" style={{ maxWidth: '402px' }}>
              <div className="pk-pane-img-wrapper">
                <picture className="pk-pane-img">
                  <source media="(max-width: 749px)" srcSet="/images/community-pw.webp" />
                  <source media="(min-width: 750px)" srcSet="/images/community-pw@2x.webp" />
                  <img src="/images/community-pw.webp" alt="" />
                </picture>
              </div>
            </div>
            <div className="pk-pane-text-section">
              <div className="pk-pane-text pk-pane-text-right">
                <h2 className="h2">
                  The most lit #PeruLovers community awaits for you!
                </h2>
                <p>Discover amazing Peruvian recipes, unique products and fun facts.</p>
                <div className="pk-pt-30 pk-pt-md-40">
                  <div className="pk-social-wrapper">
                    <h4>Find us on social media :)</h4>
                    <div className="pk-social">
                      <a href="https://www.instagram.com/peruvianwok/" target="_blank" className="pk-social-link">
                        <span className="pk-social-icon">
                          <img src="/images/icons/instagram-white.svg" width="32" height="32" alt="" />
                        </span>
                      </a>
                      <a href="https://www.tiktok.com/@peruvianwok?" target="_blank" className="pk-social-link">
                        <span className="pk-social-icon">
                          <img src="/images/icons/tik-tok-white.svg" width="32" height="32" alt="" />
                        </span>
                      </a>
                      <a href="https://www.facebook.com/Peruvian-Wok-109791377925760/" target="_blank" className="pk-social-link">
                        <span className="pk-social-icon">
                          <img src="/images/icons/facebook-white.svg" width="32" height="32" alt="" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pk-content"> 
          <div className="pk-mt-80 pk-pane-section pk-pane-spread">
            <div className="pk-pane-img-section" style={{ maxWidth: '578px' }}>
              <div className="pk-pane-img-wrapper">
                <picture className="pk-pane-img">
                  <source media="(max-width: 749px)" srcSet="/images/wok-jumping.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/wok-jumping@2x.webp"/>
                  <img src="/images/wok-jumping.webp" alt=""/>
                </picture>
              </div>
            </div>
            <div className="pk-pane-text-section">
              <div className="pk-pane-text pk-pane-text-right">
                <h2 className="h2">
                  The key is to make the ingredients jump.
                </h2>
                <p>Peruvian-Chinese flavors in a simple to love “saltado”, which means jump, because of the way it’s cooked.</p>
                <div className="pk-pt-30 pk-pt-md-40">
                  <a href="/menu" className="pk-btn pk-btn-cta is-xsmall pk-btn-rounded">
                    <span className="pk-btn-text">
                      <span className="pk-btn-copy">Discover our menu</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pk-content">
          <DealsSection page="lp" />
        </div>
        <div className="pk-content"> 
          <div className="pk-mt-80 pk-pane-section pk-pane-spread pk-pane-odd">
            <div className="pk-pane-img-section" style={{ maxWidth: '519px' }}>
              <div className="pk-pane-img-wrapper">
                <picture className="pk-pane-img">
                  <source media="(max-width: 749px)" srcSet="/images/dish-pw.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/dish-pw@2x.webp"/>
                  <img src="/images/dish-pw.webp" alt=""/>
                </picture>
              </div>
            </div>
            <div className="pk-pane-text-section">
              <div className="pk-pane-text pk-pane-text-right">
                <h2 className="h2">
                  Responsibly delicious.
                </h2>
                <p>All our dishes take in account what is relevant for all of us, regarding climate change and socially responsible farming.</p>
                <div className="pk-feature">
                  <div className="pk-feature-copy">
                    <div className="pk-hashtags">
                      <div className="pk-hashtags-bar">
                        <span className="pk-hashtag">#GressFedMeat</span>
                        <span className="pk-hashtag">#NonGMO</span>
                        <span className="pk-hashtag">#Organic</span>
                        <span className="pk-hashtag">#PeruvianFood</span>
                        <span className="pk-hashtag">#ConciousFood</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pk pk-mt-80 pk-pb-md-80">
        <div className="pk-content">
          <div className="pk-ingredients-container">
            <div className="pk-ingredients">
              <div className="pk-ingredient">
                <picture className="pk-ingredient-img">
                  <source media="(max-width: 749px)" srcSet="/images/ingredients/quinoa.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/ingredients/quinoa@2x.webp"/>
                  <img src="/images/ingredients/quinoa.webp" alt=""/>
                </picture>
                <h4 className="pk-ingredient-name">Quinoa</h4>
                <div className="pk-ingredient-icon">
                  <img src="/images/non-gmo@2x.png" alt="" />
                </div>
              </div>
              <div className="pk-ingredient">
                <picture className="pk-ingredient-img">
                  <source media="(max-width: 749px)" srcSet="/images/ingredients/meat.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/ingredients/meat@2x.webp"/>
                  <img src="/images/ingredients/meat.webp" alt=""/>
                </picture>
                <h4 className="pk-ingredient-name">Meat</h4>
                <div className="pk-ingredient-icon">
                  <img src="/images/grass-fed@2x.png" alt="" />
                </div>
              </div>
              <div className="pk-ingredient">
                <picture className="pk-ingredient-img">
                  <source media="(max-width: 749px)" srcSet="/images/ingredients/potatoes.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/ingredients/potatoes@2x.webp"/>
                  <img src="/images/ingredients/potatoes.webp" alt=""/>
                </picture>
                <h4 className="pk-ingredient-name">Peruvian potatoes</h4>
                <div className="pk-ingredient-icon">
                  <img src="/images/non-gmo@2x.png" alt="" />
                </div>
              </div>
              <div className="pk-ingredient">
                <picture className="pk-ingredient-img">
                  <source media="(max-width: 749px)" srcSet="/images/ingredients/rice.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/ingredients/rice@2x.webp"/>
                  <img src="/images/ingredients/rice.webp" alt=""/>
                </picture>
                <h4 className="pk-ingredient-name">Rice</h4>
                <div className="pk-ingredient-icon">
                  <img src="/images/non-gmo@2x.png" alt="" />
                </div>
              </div>
              <div className="pk-ingredient">
                <picture className="pk-ingredient-img">
                  <source media="(max-width: 749px)" srcSet="/images/ingredients/habas.webp"/>
                  <source media="(min-width: 750px)" srcSet="/images/ingredients/habas@2x.webp"/>
                  <img src="/images/ingredients/habas.webp" alt=""/>
                </picture>
                <h4 className="pk-ingredient-name">Peruvian Broad Beans</h4>
                <div className="pk-ingredient-icon">
                  <img src="/images/non-gmo@2x.png" alt="" />
                </div>
              </div>
              <div style={{ width: '12px' }}></div>
            </div>
          </div>
        </div>
      </section>

      <section className="pk pk-mt-80 pk-mb-80">
        <header className="pk-content pk-mb-40 pk-mb-md-50">
          <h2 className="h2">The whole Peruvian package.</h2>
          <p>We cater to all tastes! Check the rest of the family.</p>
        </header>
        <div className="pk-content">
          <div className="pk-flat-boxes">
            <a href="https://superuvian.perukitchens.com/" className="pk-flat-box">
              <picture>
                <source media="(max-width: 749px)" srcSet="/images/superuvian-bottom.webp"/>
                <source media="(min-width: 750px)" srcSet="/images/superuvian-bottom@2x.webp"/>
                <img src="/images/superuvian-bottom.webp" alt=""/>
              </picture>
            </a>
            <a href="https://burritosandsauces.perukitchens.com/" className="pk-flat-box" target="_blank">
              <picture>
                <source media="(max-width: 749px)" srcSet="/images/Burritos_Square.png"/>
                <source media="(min-width: 750px)" srcSet="/images/Burritos_Square.png"/>
                <img src="/images/Burritos_Square.png" alt=""/>
              </picture>
            </a>
            <a href="https://cevichito.perukitchens.com/" className="pk-flat-box" target="_blank">
              <picture>
                <source media="(max-width: 749px)" srcSet="/images/cevichito-bottom.webp"/>
                <source media="(min-width: 750px)" srcSet="/images/cevichito-bottom@2x.webp"/>
                <img src="/images/cevichito-bottom.webp" alt=""/>
              </picture>
            </a>
            <div style={{ width: '12px' }}></div>
          </div>
        </div>
      </section>

      <Footer />

    </React.Fragment>
  )
}

export default Home
