import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, Grid, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';
import axios from "axios";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Tab, Tabs } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { Skeleton } from '@material-ui/lab';
import StarRateIcon from '@material-ui/icons/StarRate';
import { handleDeliveryClick, handlePickUpClick } from '../../pages/home/Home';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      [theme.breakpoints.up("md")]: {
        '& .MuiDialog-paperWidthSm': {
          maxWidth: '620px'
        },
      },
    },
    paper: {
      // minHeight: '560px'
    },
    tfield: {
      marginBottom: theme.spacing(3),
      width: '220px'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    imageWrap: {
      [theme.breakpoints.down("sm")]: {
        width: "98vw"
      }
    },
    imageModal: {
      [theme.breakpoints.down("sm")]: {
        width: "98vw"
      }
    },
    dialogTitle: {
      marginBottom: theme.spacing(3),
      marginLeft: 'auto',
      textAlign: 'center',
      marginRight: 'auto',
      fontSize: '28px',
      [theme.breakpoints.down("md")]: {
        fontSize: '24px'
      },
      color: '#000',
      // fontSize: '20px',
      lineHeight: 1.3,
    },
    dialogTitleIsNarrow: {
      maxWidth: theme.spacing(40)
    },
    dialogImage: {
      display: 'block',
      marginBottom: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.between('md', 'lg')] : {
        height: '300px'
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down("md")]: {
          display: 'block',
          margin: 'auto',
          maxHeight: '270px',
          maxWidth: '480px'
        },
        [theme.breakpoints.between('md', 'lg')] : {
          // height: 'auto',
          display: 'block',
          margin: 'auto',
          width: '480px'
        }
      },
    },
    tabPanel: {
      minHeight: theme.spacing(36),
      paddingBottom:theme.spacing(6), 
      paddingTop:theme.spacing(6),
      textAlign: "center",
      display: '-webkit-box; display: flex;',
      WebkitBoxOrient: 'vertical',
      flexDirection: 'column',
      WebkitBoxPack: 'center',
      justifyContent: 'center'
    },
    tabPanelLogoBar: {
      display: '-webkit-box; display: flex;',
      flexDirection: 'column',
      alignItems: 'center'
    },
    tabPanelLogoImage: {
      height: 'auto',
      marginBottom: theme.spacing(4),
      width: theme.spacing(15),
      '&:last-child': {
        marginBottom: 0
      },
    },
    tabPanelAddress: {
      display: '-webkit-box; display: flex;',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: theme.spacing(1.5)
    },
    tabwrapper: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      '&>:first-child': {
        marginBottom: '0px!important',
        marginRight: '4px'
      } 
    },
    orderPanel: {
      display: 'block'
    },
    orderPanelHeader: {
      borderBottom: '1px solid #cfcfcf',
      paddingBottom: '40px',
      paddingTop: '32px',
      textAlign: 'center'
    },
    orderPanelHeaderTitle: {
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.0044em',
      lineHeight: '28px'
    },
    orderPanelContent: {
      paddingBottom: '60px',
      paddingTop: '40px',
      textAlign: 'center'
    },
    orderPanelContentTitle: {
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: '-0.0044em',
      lineHeight: '23px'
    },
    orderPanelLogoBar: {
      display: 'flex',
      WebkitBoxAlign: 'center',
      alignItems: 'center',
      WebkitBoxPack: 'center',
      justifyContent: 'center',
      marginTop: '30px',
      flexWrap: 'wrap',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '400px'
    },
    orderPanelLogoImage: {
      width: '117px',
      height: 'auto',
      marginRight: '20px',
      marginBottom: '10px',
      '& > img': {
        width: '100%'
      },
      '&:nth-child(3)': {
        width: '83px',
        marginRight: 0
      },
      '&:nth-child(4)': {
        width: '115px'
      },
      '&:nth-child(5)': {
        width: '100px',
        marginRight: 0
      }
    }
  }),
);

interface OrderModalPropsI {
  product?: any; // maybe change for ID only?
  open: boolean;
  onClose: any;
}

// const titles = {
//   '1': 'Before you order, let us treat you to special discounts & more!',
//   '3': 'Choose your favorite delivery platform!'
// };


const ResponsiveDialog: React.FC<OrderModalPropsI> = ({
  open = false,
  ...props
}) => {
  // const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const [step, setStep] = useState(3);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    note: 'peruvianwok'
  });
  // const handleClickOpen = () => {
  //   // setOpen(true);
  // };

  // const handleClose = () => {
  //   // setOpen(false);
  //   props
  // };

  const resetVal = () => {
    setStep(3);
  }
  useEffect(() => {
    if (!open) {
      resetVal();
    }
  }, [open])


  useEffect(() => {
    // const isthere = !!localStorage.getItem('peruvianwok_userid');
    // console.log({isthere});
    if (!props.product) {
      setStep(3);
    } else if(props.product) {
      setStep(2);
    }
  }, [open]);

  const handleSubmit = async () => {
    const data = {
      given_name: formData.fullName.split(' ').slice(0, -1).join(' '),
      family_name: formData.fullName.split(' ').slice(-1).join(' '),
      email_address: formData.email,
      note: 'peruvianwok'
    }
    try {
      const resp = await axios.post('/api/subscribe_mailing', data);
      console.log({resp});
      const user_id = resp.data.customer.id;
      // append user to localstorage
      const dataSave = {
        user_id: resp.data.customer.id
        // user_email: resp.data.customer.email
      };
  
      localStorage.setItem('peruvianwok_userid',dataSave.user_id);
      // append to group
      // setIsSubmit(true);
      if (props.product) {
        setStep(2);
      } else {
        setStep(3);
      }
    } catch (e) {
      console.log({e});
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('ahoalalalalalal');
    // console.log({event})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

    checkIputValues();
  };



  const checkIputValues = () => {
    if (formData.email && formData.fullName) {
      return false;
    }
    return true;
  }


  const [selectedTab, setSelectedTab] = useState(0);


  const handleChangeTab = (event: any, newValue: React.SetStateAction<number>) => {
    setSelectedTab(newValue);
  };

  const [mapLoaded ,setMapLoaded] = useState(false);

  const mapWillLoad = () => {
    setMapLoaded(true);
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        // className={classes.root}
        // style={{ height: '600px' }}
        classes={{
          paper:  classes.paper
        }}
        maxWidth={step == 2 ? 'md' : 'sm'}
        scroll={'body'}
      >
        <DialogTitle id="responsive-dialog-title">
          &nbsp;
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* { step == 1 && 
          <DialogTitle id="responsive-dialog-title" style={{ 'padding': '72px 72px 16px 72px', 'textAlign': 'center'}}><b>{'Before you order, let us treat you to special discounts & more!'}</b></DialogTitle>
        }
        { step == 2 && 
          <DialogTitle id="responsive-dialog-title" style={{ 'padding': '72px 72px 16px 72px'}}><b>{props.product && props.product.name}</b></DialogTitle>
        }
        { step == 3 && 
          <DialogTitle id="responsive-dialog-title" style={{ 'padding': '72px 72px 16px 72px', 'textAlign': 'center'}}><b>{'Choose your favorite delivery platform!'}</b></DialogTitle>
        } */}
        <DialogContent>
          { step == 1 && 
          
          <Grid container spacing={2} className="text-center" style={{paddingBottom:theme.spacing(8)}}>
            <h6 className={`${classes.dialogTitle} ${classes.dialogTitleIsNarrow}`}>Before you order, let us treat you to special discounts & more!</h6>
            <Grid xs={12}>
              <TextField
                autoFocus
                // margin="dense"
                id="name"
                label="Full name"
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
                variant="outlined"
                fullWidth
                className={classes.tfield}
                size="small"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                // margin="dense"
                id="email"
                label="Email Address"
                type="email"
                name="email"
                value={formData.email}
                fullWidth
                onChange={handleChange}
                variant="outlined"
                required
                className={classes.tfield}
                size="small"
              />
            </Grid>
            <Grid item xs={12} style={{'display': 'flex', 'justifyContent': 'center'}}>
              {/* {props.product && 
                <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={handleSubmit} >
                  Continue to order
                </button> ||
                <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={handleSubmit} >
                  Continue to order
                </button>
              } */}
              <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={handleSubmit} disabled={checkIputValues()}>
                Continue to order
              </button>
            </Grid>
          </Grid>
          }
          {step == 2 && 
          <Grid style={{paddingBottom:theme.spacing(4)}}>
            <h6 className={classes.dialogTitle}>{props.product && props.product.name}</h6>
            <picture className={classes.dialogImage}>
              <source media="(max-width: 749px)" srcSet={props.product.image_url + '?width=800'} />
              <source media="(min-width: 750px)" srcSet={props.product.image_url + '?width=800'}/>
              <source media="(min-width: 1600px)" srcSet={props.product.image_url + '?width=800'}/>
              <img src={props.product.image_url + '?width=800'} alt="" />
            </picture>
            <div className="text-center">
              <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={() => setStep(step + 1)}>
                Order now
              </button>
            </div>
          </Grid>
          }
          {step == 3 && 
            <div className={classes.orderPanel}>
              <header className={classes.orderPanelHeader}>
                <h2 className={classes.orderPanelHeaderTitle}>Order directly with us and save!</h2>
                <div className="pk-pt-30 pk-pt-md-40">
                  <button className="pk-btn pk-btn-cta is-xsmall pk-btn-rounded" id="square" onClick={handlePickUpClick}>
                    <span className="pk-btn-text">
                      <span className="pk-btn-copy">Order now</span>
                    </span>
                  </button>
                </div>
              </header>
              
              <div className={classes.orderPanelContent}>
                <h4 className={classes.orderPanelContentTitle}>Or find us on your favorite delivery app:</h4>
                <div className={classes.orderPanelLogoBar}>
                  <a className={classes.orderPanelLogoImage} onClick={handleDeliveryClick} id="doordash" href="https://www.doordash.com/store/peruvian-wok-san-jose-1742967/" target="_blank">
                    <img alt="" src="/images/logos/doordash-gray.svg" />
                  </a>
                  <a className={classes.orderPanelLogoImage} onClick={handleDeliveryClick} id="postmates" href="https://postmates.com/store/peruvian-wok-949-ruff-drive/oGOL4UByR3yeTbxLo51iXQ" target="_blank">
                    <img alt="" src="/images/logos/postmates-gray.svg" />
                  </a>
                  <a className={classes.orderPanelLogoImage} onClick={handleDeliveryClick} id="grubhub" href="https://www.grubhub.com/restaurant/peruvian-wok-949-ruff-drive-san-jose/2735297" target="_blank">
                    <img alt="" src="/images/logos/grub-hub-gray.svg"/>
                  </a>
                  <a className={classes.orderPanelLogoImage} onClick={handleDeliveryClick} id="ubereats" href="https://www.ubereats.com/store/peruvian-wok-949-ruff-drive/oGOL4UByR3yeTbxLo51iXQ" target="_blank">
                    <img alt="" src="/images/logos/uber-eats-gray.svg" />
                  </a>
                  <a className={classes.orderPanelLogoImage} onClick={handleDeliveryClick} id="allset" href="https://s.allsetnow.com/MbUlxop" target="_blank">
                    <img alt="" src="/images/logos/allset-gray.png" />
                  </a>
                </div>
              </div>
              {/* <div>
                <Tabs indicatorColor="primary" value={selectedTab} onChange={handleChangeTab} centered variant="fullWidth">
                  <Tab label="Pick up" icon={<StarRateIcon fontSize="small" color="primary" />} classes={{ wrapper: classes.tabwrapper }}/>
                  <Tab label="Choose delivery"/>
                </Tabs>
              </div>

              {selectedTab === 0 && 
                <div className={classes.tabPanel}>
                  <div style={{ margin: 'auto', padding: '4px 8px', fontSize: '12px',  fontWeight: 500, backgroundColor: theme.palette.primary.light}}>
                    BEST VALUE!
                  </div>
                  <div className={classes.tabPanelAddress} style={{fontWeight: 700, paddingTop: '12px'}}>
                    Pick up in our conveniently located address and save in your order!
                  </div>
                 
                  
                  <Grid item xs={12}>
                    { !mapLoaded &&
                      <><Skeleton variant="rect" width={300} height={154} style={{margin: 'auto'}} /></>
                    }
                      <div className="mapouter" style={{display: mapLoaded ? 'block': 'none'}}><div className="gmap_canvas"><iframe onLoad={mapWillLoad} width="100%" height="280" id="gmap_canvas" src="https://maps.google.com/maps?q=949%20Ruff%20Drive%20San%20Jose,%20CA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe></div></div>
                    </Grid>
                  <div className={classes.tabPanelAddress} style={{ paddingTop: theme.spacing(4), paddingBottom: '0px ' }}>
                    <RoomIcon color="primary" fontSize="small"/>&nbsp;949 Ruff Drive San Jose, CA
                  </div>
                  <div className="pk-pt-30 pk-pt-md-40" style={{ paddingTop: theme.spacing(4)}}>
                    <button className="pk-btn pk-btn-cta is-xsmall pk-btn-rounded" id="square" onClick={handlePickUpClick}>
                      <span className="pk-btn-text">
                        <span className="pk-btn-copy">Continue</span>
                      </span>
                    </button>
                  </div>
                </div>
              }
              {selectedTab === 1 && 
                <div className={classes.tabPanel} style={{ minHeight: '360px' }}>
                  <div className={classes.tabPanelLogoBar}>
                    <a className={classes.tabPanelLogoImage} onClick={handleDeliveryClick} id="doordash" href="https://www.doordash.com/store/peruvian-wok-san-jose-1742967/" target="_blank">
                      <img alt="" src="/images/logos/doordash-black.svg" />
                    </a>
                    <a className={classes.tabPanelLogoImage} onClick={handleDeliveryClick} id="ubereats" href="https://www.ubereats.com/store/peruvian-wok-949-ruff-drive/oGOL4UByR3yeTbxLo51iXQ" target="_blank">
                      <img alt="" src="/images/logos/uber-eats-black.svg" />
                    </a>
                    <a className={classes.tabPanelLogoImage} onClick={handleDeliveryClick} id="postmates" href="https://postmates.com/store/peruvian-wok-949-ruff-drive/oGOL4UByR3yeTbxLo51iXQ" target="_blank">
                      <img alt="" src="/images/logos/postmates-black.svg" />
                    </a>
                    <a className={classes.tabPanelLogoImage} onClick={handleDeliveryClick} id="grubhub" href="https://www.grubhub.com/restaurant/peruvian-wok-949-ruff-drive-san-jose/2735297" target="_blank" >
                      <img alt="" src="/images/logos/grub-hub-black.svg" id="grubhub"/>
                    </a>
                  </div>
                </div>
              } */}
            </div>
          }
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default ResponsiveDialog;

// const ResponsiveDialog: React.FC<OrderModalPropsI> = ({
//   open = false,
//   ...props
// }) => {
//   // const [open, setOpen] = React.useState(false);
//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
//   // const shouldShowFS = useMediaQuery(theme.breakpoints.down('lg'));
//   const classes = useStyles();
//   const [step, setStep] = useState(3);
//   const [isSubmit, setIsSubmit] = useState(false);

//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: '',
//     note: 'peruvianwok'
//   });
//   // const handleClickOpen = () => {
//   //   // setOpen(true);
//   // };

//   // const handleClose = () => {
//   //   // setOpen(false);
//   //   props
//   // };
//   const [selectedTab, setSelectedTab] = useState(0);

//   const resetVal = () => {
//     setStep(3);
//   }
//   useEffect(() => {
//     if (!open) {
//       resetVal();
//     }
//   }, [open]);

//   useEffect(() => {
//     // const isthere = !!localStorage.getItem('peruvianwok_userid');
//     // console.log({isthere});
//     console.log(props.product)
//     if (!props.product) {
//       setStep(3);
//     } else if(props.product) {
//       setStep(2);
//     }
//   }, [open]);


//   const handleSubmit = async () => {
//     // const data = {
//     //   given_name: formData.fullName.split(' ').slice(0, -1).join(' '),
//     //   family_name: formData.fullName.split(' ').slice(-1).join(' '),
//     //   email_address: formData.email,
//     //   note: 'peruvianwok'
//     // }
//     // const token = 'EAAAEJ3l80oRjtviVc88hF3e3p_XqtOHmMz-3Z2n3Qrlh9VWEp4GQyvQnAFJFFWr';

//     // try {
//     //   const resp = await axios.post('/square/customers', data);
//     //   console.log({resp});
//     //   const user_id = resp.data.customer.id;
//     //   // append user to localstorage
//     //   const dataSave = {
//     //     user_id: resp.data.customer.id
//     //     // user_email: resp.data.customer.email
//     //   };
  
//     //   localStorage.setItem('peruvianwok_userid',dataSave.user_id);
//     //   // append to group
//     //   setIsSubmit(true);
//     //   if (props.product) {
//     //     setStep(2);
//     //   } else {
//     //     setStep(3);
//     //   }
//     // } catch (e) {
//     //   console.log({e});
//     //   // return res.status(e.status || 500).end(e.message);
//     // }
//     localStorage.setItem('peruvianwok_userid','fakeid');
//     // append to group
//     setIsSubmit(true);
//     if (props.product) {
//       setStep(2);
//     } else {
//       setStep(3);
//     }
//   }

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     console.log('ahoalalalalalal');
//     console.log({event})
//     setFormData({
//       ...formData,
//       [event.target.name]: event.target.value
//     });

//     checkIputValues();
//   };



//   const checkIputValues = () => {
//     if (formData.email && formData.fullName) {
//       return false;
//     }
//     return true;
//   }

  

//   const handleChangeTab = (event: any, newValue: React.SetStateAction<number>) => {
//     setSelectedTab(newValue);
//   };

//   // console.log({step});
//   console.log({props});
//   return (
//     <div>
//       <Dialog
//         fullScreen={fullScreen}
//         fullWidth
//         open={open}
//         onClose={props.onClose}
//         aria-labelledby="responsive-dialog-title"
//         className={classes.main}
//         maxWidth={step == 2 ? 'md' : 'sm'}
//       >
//         <DialogTitle id="responsive-dialog-title">
//           &nbsp;
//           <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         {/* { step == 1 && 
//           <DialogTitle id="responsive-dialog-title" style={{ 'padding': '72px 72px 16px 72px', 'textAlign': 'center'}}><b>{'Before you order, let us treat you to special discounts & more!'}</b></DialogTitle>
//         }
//         { step == 2 && 
//           <DialogTitle id="responsive-dialog-title" style={{ 'padding': '72px 72px 16px 72px'}}><b>{props.product && props.product.name}</b></DialogTitle>
//         }
//         { step == 3 && 
//           <DialogTitle id="responsive-dialog-title" style={{ 'padding': '72px 72px 16px 72px', 'textAlign': 'center'}}><b>{'Choose your favorite delivery platform!'}</b></DialogTitle>
//         } */}
//         <DialogContent>
//           { step == 1 && 
          
//           <Grid container spacing={2} className="text-center" style={{paddingBottom:theme.spacing(8)}}>
//             <h6 className={`${classes.dialogTitle} ${classes.dialogTitleIsNarrow}`}>Before you order, let us treat you to special discounts & more!</h6>
//             <Grid xs={12}>
//               <TextField
//                 autoFocus
//                 // margin="dense"
//                 id="name"
//                 label="Full name"
//                 type="text"
//                 name="fullName"
//                 value={formData.fullName}
//                 onChange={handleChange}
//                 required
//                 variant="outlined"
//                 fullWidth
//                 className={classes.tfield}
//                 size="small"
//               />
//             </Grid>
//             <Grid xs={12}>
//               <TextField
//                 // margin="dense"
//                 id="email"
//                 label="Email Address"
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 fullWidth
//                 onChange={handleChange}
//                 variant="outlined"
//                 required
//                 className={classes.tfield}
//                 size="small"
//               />
//             </Grid>
//             <Grid item xs={12} style={{'display': 'flex', 'justifyContent': 'center'}}>
//               {/* {props.product && 
//                 <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={handleSubmit} >
//                   Continue to order
//                 </button> ||
//                 <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={handleSubmit} >
//                   Continue to order
//                 </button>
//               } */}
//               <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={handleSubmit} disabled={checkIputValues()}>
//                 Continue to order
//               </button>
//             </Grid>
//           </Grid>
//           }
//           {step == 2 && 
//           <Grid style={{paddingBottom:theme.spacing(4)}}>
//             <h6 className={classes.dialogTitle}>{props.product && props.product.name}</h6>
//             <picture className={classes.dialogImage}>
//               <source media="(max-width: 749px)" srcSet={`/images/modal/${props.product.image}.webp`} />
//               <source media="(min-width: 750px)" srcSet={`/images/modal/${props.product.image}.webp`}/>
//               <source media="(min-width: 1600px)" srcSet={`/images/modal/${props.product.image}.webp`}/>
//               <img src={`/images/modal/${props.product.image}.webp`} alt=""  />
//             </picture>
//             <div className="text-center">
//               <button className="pk-btn-cta pk-btn is-small pk-btn-rounded" onClick={() => setStep(step + 1)}>
//                 Order now
//               </button>
//             </div>
//           </Grid>
//           }
//           {step == 3 && 
//             <div style={{marginLeft: '-24px', marginRight: '-24px', marginTop: '-8px'}}>
//               <div>
//                 <Tabs value={selectedTab} onChange={handleChangeTab} centered variant="fullWidth">
//                   <Tab label="Pick up"/>
//                   <Tab label="Choose delivery"/>
//                 </Tabs>
//               </div>

//               {selectedTab === 0 && 
//                 <div className={classes.tabPanel}>
//                   <div className={classes.tabPanelAddress} style={{fontWeight: 700}}>
//                     Pick up location address:
//                   </div>
//                   <div className={classes.tabPanelAddress}>
//                     <RoomIcon color="primary" fontSize="small"/>&nbsp;949 Ruff Drive San Jose, CA
//                   </div>
//                   <div className="pk-pt-30 pk-pt-md-40">
//                     <button className="pk-btn pk-btn-cta is-xsmall pk-btn-rounded" onClick={() => window.open('https://order.perukitchens.com/peruvianwok', '_blank')}>
//                       <span className="pk-btn-text">
//                         <span className="pk-btn-copy">Order and save</span>
//                       </span>
//                     </button>
//                   </div>
//                 </div>
//               }
//               {selectedTab === 1 && 
//                 <div className={classes.tabPanel}>
//                   <div className={classes.tabPanelLogoBar}>
//                     <img className={classes.tabPanelLogoImage} alt="" src="/images/logos/doordash-black.svg" />
//                     <img className={classes.tabPanelLogoImage} alt="" src="/images/logos/uber-eats-black.svg" />
//                     <img className={classes.tabPanelLogoImage} alt="" src="/images/logos/postmates-black.svg" />
//                     <img className={classes.tabPanelLogoImage} alt="" src="/images/logos/grub-hub-black.svg" />
//                   </div>
//                 </div>
//               }
//             </div>
//           }
//         </DialogContent>
//         {/* <DialogActions>
//           <Button autoFocus onClick={handleClose} color="primary">
//             Disagree
//           </Button>
//           <Button onClick={handleClose} color="primary" autoFocus>
//             Agree
//           </Button>
//         </DialogActions> */}
//       </Dialog>
//     </div>
//   );
// }

// export default ResponsiveDialog;
